import { Link, navigate, useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import React from "react";
import { SummitRegistrationQuery } from "../../../graphql-types";
import { Helmet } from "react-helmet";
import MenuBar from "../../components/MenuBar";
import PageContent from "../../components/PageContent";
import HeroBanner from "../../components/HeroBanner";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import ElevateButton from "../../components/ElevateButton";
import ReactMarkdown from "react-markdown";

const HomePage = () => {
    const registrationPageContent = useStaticQuery<SummitRegistrationQuery>(query).data;
    const registrationPageCards = useStaticQuery<SummitRegistrationQuery>(query).eventCards.edges;

    const heroImage = getImage(registrationPageContent?.registrationPageHeroImage?.localFile?.childImageSharp);
    const { registrationPageHeroImageText, registrationPageHeroDescription, registrationPageTravelArrangementsText, registrationPageTravelArrangementsTitle, rsvpSubtext, rsvpTitle, rsvpImage } = registrationPageContent;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Summit Registration</title>
            </Helmet>
            <MenuBar />
            <div>
                <HeroBanner image={heroImage} title={<div>ELEVATE<br></br>SUMMIT</div>} description={registrationPageHeroDescription.data.childMarkdownRemark.rawMarkdownBody} summitPage={true} objectPosition={"right bottom"} />
            </div>
            <div className="flex flex-col lg:flex-row bg-off-white text-center justify-around">
                {
                    registrationPageCards.map((card) => {
                        return (
                            <div className="flex flex-col justify-center h-[200px] m-5 items-center" key={card.node.strapi_id}>
                                <div className="text-[#FDB731] text-xl mb-2 font-semibold">{card.node.title}</div>
                                <div
                                    className="text-base leading-snug font-semibold max-w-[380px] prose"
                                >{card.node.description}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="w-full flex flex-col md:flex-row justify-center md:h-[75px] my-[25px] gap-3 items-center">
                <ElevateButton className="max-w-[282px]">
                    <div className="w-[250px] text-base">
                        <a href="/summit/agenda" target="_blank">See the Agenda</a>
                    </div>
                </ElevateButton>
                <ElevateButton className="max-w-[282px]">
                    <div className="w-[250px] text-base">
                        <a href="/summit/speakers" target="_blank">Speaker Info Here</a>
                    </div>
                </ElevateButton>
            </div>

            {/* Desktop */}
            <div className="hidden lg:block">
                <PageContent className="bg-white mb-28">
                    <div className="flex w-full justify-between">
                        <div className="w-[90%] mr-12">
                            <div className="text-blue font-bold text-2xl 2xl:text-4xl w-full text-center mb-16">
                                {registrationPageTravelArrangementsTitle}
                            </div>
                            <ReactMarkdown
                                className="prose text-base font-semibold"
                                components={{ h5: ({ node, ...props }) => <h5 style={{ fontWeight: "bold", color: "#FF6633" }} {...props} />, a: ({ node, ...props }) => <a target="_blank" style={{ cursor: "pointer" }} {...props} /> }}
                            >
                                {registrationPageTravelArrangementsText.data.childMarkdownRemark.rawMarkdownBody}
                            </ReactMarkdown>
                        </div>
                        <div>
                            <GatsbyImage image={rsvpImage.localFile.childImageSharp.gatsbyImageData} alt={""} className="max-h-[1000px]"/>
                        </div>
                    </div>
                </PageContent>
            </div>

            {/* Mobile */}
            <div className="lg:hidden">
                <PageContent className="bg-white pb-8">
                    <div className="flex flex-col">
                        <div className="">
                            <div className="text-blue font-bold text-xl text-center mb-8">
                                {registrationPageTravelArrangementsTitle}
                            </div>
                            <ReactMarkdown
                                className="prose text-base font-semibold"
                                components={{ h5: ({ node, ...props }) => <h5 style={{ fontWeight: "bold", color: "#FF6633" }} {...props} />, a: ({ node, ...props }) => <a target="_blank" style={{ cursor: "pointer" }} {...props} /> }}
                            >
                                {registrationPageTravelArrangementsText.data.childMarkdownRemark.rawMarkdownBody}
                            </ReactMarkdown>
                        </div>
                    </div>
                </PageContent>
            </div>
        </>
    )
}

export default HomePage;


export const query = graphql`
query SummitRegistration {
  data: strapiSummitRegistrationPage {
    registrationPageHeroDescription {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
    }
    registrationPageHeroImageText
    registrationPageTravelArrangementsText {
        data {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
    }
    registrationPageTravelArrangementsTitle
    rsvpSubtext
    rsvpTitle
    registrationPageHeroImage {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
    rsvpImage {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  },
  eventCards: allStrapiSummitEventDescriptionCard(sort: {fields: strapi_id, order: ASC}) {
    edges {
      node {
        title
        description
        strapi_id
      }
    }
  }
}
`;